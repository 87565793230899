import React, { useEffect } from 'react';
import './DeviceLocation.css'
import { Card, CardMedia, CardContent, Typography, Box, Button } from '@mui/material';

import Footer from '../HomePage/Footer/Footer';
import img from '../../Assets/heroImg.jpeg';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState } from 'react'
import LocationImg from '../../Assets/Location retrieval.png'
import DeviceSwaggerApiReference from './deviceApiReference/DeviceSwaggerApiReference';

const DeviceLocation = () => {

  const [Tabvalue, setTabValue] = useState('1');

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };



  // for page open to the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // API Reference Tab panel states 
  const [ApiReferencevalue, setApiReferenceValue] = useState('1');

  const handleAPITab = (event, newValue) => {
    setApiReferenceValue(newValue);
  };

  return (
    <>

      <Box className='DeviceLocation' sx={{ typography: 'body1' }}>
        <div className='DeviceLocationHeading'>

          <h1>Device Location - CAMARA Sandbox - startelelogic lab 0.1 </h1>

          {/* <div className='DeviceLocationicon'>
            <div style={{ width: '70%' }}>
              <p>Implement precise location-based services by confirming if a device is within a specified geographical area</p>
              <br />

              <Button variant="contained" size="large">
                Use this API
              </Button>
              <Button variant='outlined' size="large" style={{ margin: '10px ' }} onClick={FindIPAddress} > Contact us</Button>

            </div>
            <img src={LocationImg} />
          </div> */}


        </div>
        <div className='DeviceLocationTabDetails'>
          <TabContext value={Tabvalue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Overview" value="1" />
                <Tab label="API Reference" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <div className='OverviewDetails'>

                <p className='main-heading'>CAMARA Sandbox - Device Location - startelelogic lab <span style={{ fontSize: '18px', fontWeight: '500', }}>API is part of</span> <span id='Apidocs' style={{ textDecoration: 'underline', }}> CAMARA APIs by startelelogic.</span>
                </p>

                <p >
                  This sandboxed version is limited to a short list of mobile devices (MSISDNs) that only work in our lab.
                </p>

                <h3>What it does?</h3>

                <p>
                  The CAMARA D﻿evice Location Retrieval API allows to retrieve the mobile device's geographical area position (via a circle in startelelogic implementation). The retrieved area depends on the network conditions at the subscriber's location.
                </p>

                <h3>How it works?</h3>
                <p>The requester provides the mobile ID. The response provides a geographical area defined with longitude /
                  latitude number and an accuracy radius.

                </p>
              </div>

            </TabPanel>

            {/* API Reference Tab Start here  */}
            <TabPanel value="3">
              
              <Box sx={{ typography: 'body1' }}>
                <TabContext value={ApiReferencevalue}>
                  
                  <Box sx={{ borderColor: 'divider' }}>
                    <TabList onChange={handleAPITab} aria-label="lab API tabs example">
                      <Tab label="SwaggerUI" value="1" />
                      {/* <Tab label="Rapidoc" value="2" /> */}
                    </TabList>
                  </Box>

                  {/* SwwagerUI code start here  */}
                  <TabPanel value="1">
                    <div className='ApiReferenceTab'></div>
                  <DeviceSwaggerApiReference/>

                  </TabPanel>
                  {/* <TabPanel value="2">Item Two</TabPanel> */}
                </TabContext>
              </Box>
            </TabPanel>



            {/* <TabPanel value="4">
              Terms
            </TabPanel>
            <TabPanel value="5">Contact us</TabPanel> */}
          </TabContext>

        </div>

      </Box>

      <Footer/>


    </>
  );
};

export default DeviceLocation;