import React from 'react'
import './HomePage.css'
import HeroSection from '../HeroSection/HeroSection';
import Products from './Products/Products'
import APIKeys from './API/APIKeys'
import NetworkApi from './NetworkApi/NetworkApi'
import APICatalog from './APICatalog/APICatalog'
import Footer from './Footer/Footer'



const HomePage = () => {
    return (
        <>
            <div className='HomePage'>
                <div className='main_box'>
                    <HeroSection/>
                </div>
            </div>

            <Products/>
            {/* <APIKeys /> */}
            {/* <APICatalog /> */}
            <NetworkApi />
           

            <Footer />

            {/* <ScrollToTop/> */}
        </>
    )
}

export default HomePage;






