import * as React from 'react';
import { AppBar, Toolbar, Button, Grid, Typography, Box, Container } from '@mui/material';
import ApiIcon from '@mui/icons-material/Api';
import './DeveloperCenter.css'
import ApiKey from '../../ApiKey/ApiKey';
import Footer from '../Footer/Footer';

export default function DeveloperCenterPage() {
  return (
    <div>
     
      

     <div className="developer-hero-container">
     <div className="developer-hero-bg-image" />
     <Box className="developer-hero-content">
        
        <Box className="developer-hero-text">
          <Typography variant="h1" gutterBottom className="developer-hero-title">
            Welcome to Developer Center
          </Typography>
          <Typography variant="body1" paragraph className="developer-hero-description">
          Empower your innovation with cutting-edge tools and APIs. Join us to unlock limitless possibilities in technology and development
          </Typography>
    
        </Box>
        </Box>
        </div>
       


     

      {/* Main Features */}
      <Container sx={{ mt: 6,mb:2 }}>
        <Typography variant="h3" textAlign="center" gutterBottom>
          What We Offer <ApiIcon fontSize='larger'/> 
        </Typography>

    <ApiKey/>
       
      </Container>

      {/* Footer */}
      <Footer/>
    </div>
  );
}
