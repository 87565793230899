import React, { useState } from "react";
import "./NetworkApi.css"; // Create a CSS file for styling
import { Card, CardMedia, CardContent, Typography, Box, Button } from '@mui/material';
// import cardImg from '../../Assets/codeImg.png'

const NetworkApi = () => {
  

    return (
        <>
            <div className="NetworkApi">
                <div className="NetworkApi-bg-image"></div>
                <Box className="NetworkApi-content">
                    <Box className="NetworkApi-text">
                        <Typography variant="h4" gutterBottom>
                        Network APIs (startelelogic), the new era of digital services and mobile apps
                        </Typography>
                        <Typography variant="body1" paragraph>
                        This initiative aims at simplifing the developers’ experience and facilitating the integration with the networks. Through GSMA Open Gateway, telcos are defining and promoting common standards for APIs designed to provide universal access to operator networks for developers and hyperscalers.
                        </Typography>
                        {/* <Button variant="outlined" color="white" size="large">
                            Discover our set of Network APIs
                        </Button> */}
                    </Box>

                    {/* Right Section: Image Card */}
                    <Card className="NetworkApi-card">
                 

                    </Card>
                </Box>
            </div>
        </>
    );
};

export default NetworkApi;
