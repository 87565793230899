import React, { useEffect } from 'react';
import './DeviceStatusMonitoring.css';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState } from 'react';
import DeviceStatusMonitoringApiReference from './swaggerDeviceApi/DeviceStatusMonitoringApiReference';
import Footer from '../HomePage/Footer/Footer';
// import ApiReference from './ApiReference/ApiReference';

const DeviceStatusMonitoring = () => {
    const [TabValue, setTabValue] = useState('1');

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // for open the page to the top
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
      <>

        <Box className='DeviceStatusBox' sx={{ typography: 'body1' }}>
            <div className='DeviceStatusHeading'>
                <h1>Device Status Monitoring - CAMARA Sandbox - startelelogic Lab 0.1</h1>
            </div>
            <div className='DeviceStatusTabDetails'>
                <TabContext value={TabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label="Overview" value="1" />
                            <Tab label="API Reference" value="3" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <div className='OverviewDetails'>
                            <p className='main-heading'>CAMARA Sandbox - Device Status Monitoring API is part of <span id='ApiDocs' style={{ textDecoration: 'underline' }}>CAMARA APIs by startelelogic.</span></p>
                            <p>This sandboxed version is limited to a short list of mobile devices (MSISDNs) that only work in our lab.</p>
                            <h3>What it does?</h3>
                            <p>The CAMARA Device Status Monitoring API performs real-time checks on the status of devices connected to the network. It provides insights into the device's current status and any changes that may occur.</p>
                            <p>This API is useful for monitoring device health and ensuring proper functionality within a network.</p>
                            <p>The Device Status Monitoring API provides management of resources answering distinct questions:</p>
                            <ul>
                                <li>What is the current status of the device?</li>
                                <li>When was the last status change for the device?</li>
                            </ul>
                        </div>
                    </TabPanel>
                    <TabPanel value="3">
                      <DeviceStatusMonitoringApiReference/>
                    </TabPanel>
                </TabContext>
            </div>
        </Box>
        <Footer/>
        </>
    );
};

export default DeviceStatusMonitoring;
