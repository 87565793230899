
import React from 'react'
import {   Tab } from '@mui/material'
import DownloadingIcon from '@mui/icons-material/Downloading';
import './ApiReference.css'
import { Card, CardMedia, CardContent, Typography, Box, Button } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react'
import { lookup } from 'country-data';  // import the country-data package


const ApiReference = () => {

  const LoginStatus = localStorage.getItem('isLoggedIn')
  const [country, setCountry] = useState('');
  const UserUUId = localStorage.getItem('UserUUId')
  const AuthToken = localStorage.getItem('AuthToken'); // for getting the auth token from local storage

  // API Reference Tab panel states 
  const [ApiReferencevalue, setApiReferenceValue] = useState('1');

  const handleAPITab = (event, newValue) => {
    setApiReferenceValue(newValue);
  };


// OpenApiSpec function calling for show the api name, url , parameter in the swagger and response status 

  const openApiSpec = {
    "openapi": "3.0.0",
    "info": {
      "title": "SIM Swap",
      "version": "1.0.0"
    },
    "components": {
      "securitySchemes": {
        "bearerAuth": {
          "type": "http",
          "scheme": "bearer",
          "bearerFormat": "JWT"
        }
      },
      
      "parameters": {
        "ContentType": {
          "name": "Content-Type",
          "in": "header",
          "required": true,
          "schema": {
            "type": "string",
            "default": "application/json"
          },
          "description": "Content type for the request"
        }
      }
    },
    "security": [
      {
        "bearerAuth": []
      }
    ],
    "servers": [
      {
        "url": "https://camara-api.callibry.com:3001/api/v1"
      }
    ],
    "paths": {
      "/sim-swap/check": {
        "post": {
          "tags": ["SIM Swap"],
          "summary": "SIM Swap ",
          "requestBody": {
            "description": "SIM Swap ",
            "required": true,
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "phoneNumber": {
                      "type": "string",
                      "default": "+33699901032"

                    },
                    "maxAge": {
                      "type": "integer",
                      "default": 60
                    },

                  }
                }
              }
            }
          },
          "responses": {
            "200": {
              "description": "Ok"
            },
            "400": {
              "description": "Bad Request"
            },
            "401": {
              "description": "Unauthorized"
            },
            "403": {
              "description": "Forbidden"
            },
            "404": {
              "description": "Not Found"
            },
            "405": {
              "description": "Method Not Allowed"
            },
            "500": {
              "description": "Internal Server Error"
            },
            "502": {
              "description": "Bad Gateway"
            },

          }
        }
      }
    }
  };





  // ResponesInterceptor for store the called api response for sending the logs apis parameter

  const responseInterceptor = async (res) => {
    // const data = res.body;
    // console.log(data, 'dataaaaaaaaaaaaaaa');

    if (res.status === 401) {
      // Display a custom message to the user
      alert("Unauthorized access. Please access your token from the profile page.");
      window.location.href = '/user-setting';
    }

    const FirstName = localStorage.getItem('FirstName');
    const LastName = localStorage.getItem('LastName');

    const FullName = FirstName.trim() + " " + LastName.trim()

    // console.log(FullName, 'ffffffffffff')

    const date = new Date();

    // formatin the date 
    const UTCFormatDate = date.toISOString();



    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    // Getting the Device ip address and network location and sending this details into an logs api

    const IPRes = await fetch("https://ipinfo.io/122.161.51.67?token=781b8c09d82125", requestOptions)

    const IPResponse = await IPRes.json();
    const countryCode = IPResponse.country;

    // Convert the iso2 to full country name 
    const getCountryName = (iso2Code) => {
      const countryData = lookup.countries({ alpha2: iso2Code })[0];
      return countryData ? countryData.name : 'Unknown Country';
    }

    const countryName = getCountryName(countryCode) || 'Unknown Country';
    // setCountry(countryName);






//  logs api url 

    await fetch('https://camara-api.callibry.com:3001/api/v1/logs', {
      method: 'POST', headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${AuthToken}`
      }, body: JSON.stringify({
        "status": "1",
        "method": "POST",
        "endpoint": "/get-device-location",
        "uuid": UserUUId,
        "name": FullName,
        "ipAddress": IPResponse.ip,
        "city": IPResponse.city,
        "region": IPResponse.region,
        "country":countryName,
        "location": IPResponse.loc,
        "org": IPResponse.org,
        "postal": IPResponse.postal,
        "timezone": IPResponse.timezone,
        "mac": "",
        "date": UTCFormatDate,
      }
      )
    })
      .then(response => response.json())
      .then(data => {
        // console.log(data); 
      })

    return res;
  }

  return (
    <>
      <div className="main-container-sim-swap">



        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={ApiReferencevalue}>
            <Box sx={{ borderColor: 'divider' }}>
              <TabList onChange={handleAPITab} aria-label="lab API tabs example">
                <Tab label="SwaggerUI" value="1" />
                {/* <Tab label="Rapidoc" value="2" /> */}
              </TabList>
            </Box>
            <TabPanel value="1">
              <div className="swagger-mainContainer">
                <div className="simSwap-content">
                  <div className="simSwap-title">
                    <h2>SIM Swap</h2>
                    <p>The SIM Swap API provides a programmable interface for developers and other users (capabilities consumers) to request the last date of a SIM Swap performed on the mobile line, or, to check whether a SIM swap has been performed during a past period.</p>
                  </div>


                </div>
                <div className="introduction-content">
                  <div className="simSwap-title">
                    <h2>Introduction</h2>
                    <p>The SIM Swap API performs real-time checks on the activation date of a SIM card on the mobile network. It reveals if an individual mobile phone number (MSISDN) has been ported to another SIM card.</p>
                    <p>The SIM Swap API is useful to prevent fraud by reducing the risk of account takeover fraud by strengthening SIM based authentication processes such as SMS One-time passwords. Fraudsters are using SIM swap techniques to intercept SMS messages and reset passwords or receive verification codes that allow them to access protected accounts.</p>
                    <p>This API is used by an application to get information about a mobile line latest SIM swap date. It can be easily integrated and used through this secured API and allows SPs (Service Provider) to get this information an easy & secured way. The API provides management of 2 resources answering 2 distinct questions:</p>
                    <p><li>When did the last SIM swap occur?</li>
                      <li>Has a SIM swap occurred during last n hours?</li>
                    </p>
                  </div>
                </div>



                <div className="relevent-terms-content">
                  <div className="relevent-terms-title">
                    <h2>Relevant terms and definitions</h2>
                    <p><li>
                      SIM swap: A SIM swap is a process in which a mobile phone user's current SIM card is deactivated or replaced with a new one. This is typically done by contacting the user's mobile service provider and requesting a new SIM card for various reasons, such as a lost or damaged SIM card, upgrading to a new phone, or changing phone numbers while keeping the same device.
                    </li></p>
                  </div>



                </div>


                <div className="api-functionality-content">
                  <div className="api-functionality-title">
                    <h2>API functionality</h2>
                    <p>The API provides 2 operations:</p>
                    <p>
                      <li>POST retrieve-date : Provides timestamp of latest SIM swap, if any, for a given phone number.

                      </li>
                    </p>

                    <p>
                      <li>POST check: Checks if SIM swap has been performed during a past period (defined in the request with 'maxAge' attribute) for a given phone number.

                      </li>
                    </p>
                  </div>



                </div>


                <div className="further-info-content-content">
                  <div className="further-info-title">
                    <h2>Further-info-content</h2>
                    <p>GSMA Mobile Connect Account Takeover Protection specification was used as source of input for this API. For more about Mobile Connect, please see Mobile Connect website.
                    </p>
                    <p>(FAQs will be added in a later version of the documentation)</p>


                  </div>
                </div>


              </div>

              {
                LoginStatus === "true" ? (<Card className="hero-card">

                   {/* Show the swagger ui for loggedIn user  */}

                  {/* <SwaggerUI url="https://camara-api.callibry.com:3001/login"/> */}
                  <SwaggerUI spec={openApiSpec}  responseInterceptor={responseInterceptor} />


                </Card>) : (
                  <>
                    <div className="login-message">
                      <a href='/login'>Please Login to Access Full API Features</a>
                      <p>
                        You need to log in to fully interact with the APIs, but you can still explore the API documentation below.
                      </p>
                    </div>

                    <Card className="hero-card">

                      {/* Render Swagger UI for non-logged-in users as read-only */}

                      <SwaggerUI
                        spec={openApiSpec}
                        
                        responseInterceptor={responseInterceptor}
                        docExpansion="none" // Collapse the docs for easier reading
                        supportedSubmitMethods={LoginStatus === "true" ? ['get', 'post', 'put', 'delete'] : []} // Disable try it out if not logged in
                      />
                    </Card>

                    <div className="swagger-info">
                      <h3 style={{ marginTop: '20px' }} >How to Use Our APIs</h3>
                      <p>
                        Our APIs provide a range of services that you can explore through the documentation above.
                        You can use this interface to test API endpoints once you log in. For now, you can review the
                        available endpoints and see how to structure your requests.
                      </p>
                      <ul>
                        <li><strong>Authentication:</strong> Login to get your API Access.</li>
                        <li><strong>Endpoints:</strong> View available services, such as device location, SIM swap detection,Device status monitoring and OTP validation.</li>
                        <li><strong>Request format:</strong> Each endpoint shows example requests and response formats.</li>
                      </ul>
                      <p>
                        {/* For more information, please visit our <a href="/api-docs">API documentation page</a> or contact support. */}
                      </p>
                    </div>
                  </>
                )
              }

            </TabPanel>
            {/* <TabPanel value="2">Item Two</TabPanel> */}
          </TabContext>
        </Box>





      </div>

    </>
  )
}

export default ApiReference
