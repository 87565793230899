import React, { useEffect } from 'react';


import './SIMSwapDetection.css'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState } from 'react'

import ApiReference from './ApiReference/ApiReference';
import Footer from '../HomePage/Footer/Footer';

const SIMSwapDetection = () => {
  const [Tabvalue, setTabValue] = useState('1');

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // For open the page to the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>

      <Box className='SimSwapBox' sx={{ typography: 'body1' }}>
        <div className='SimSwapHeading'>

          <h1>SIM Swap - CAMARA Sandbox - startelelogic lab 0.1 </h1>
          {/*  */}
          {/* <div className='SimSwapicon'>
            <div style={{ width: '70%' }}>
              <p>Integrate real-time SIM card activation checks to enhance security and prevent fraud</p>
              <br />

              <Button variant="contained" size="large">
                Use this API
              </Button>
              <Button variant='outlined' size="large" style={{ margin: '10px ' }}> Contact us</Button>

            </div>
            <img src={SIMSwap} />
          </div> */}


        </div>
        <div className='SimSwapTabDetails'>
          <TabContext value={Tabvalue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Overview" value="1" />
                {/* <Tab label="Getting Started" value="2" /> */}
                <Tab label="API Reference" value="3" />
                {/* <Tab label="Terms" value="4" />
                <Tab label="Contact us" value="5" /> */}
              </TabList>
            </Box>
            <TabPanel value="1">
              <div className='OverviewDetails'>

                <p className='main-heading'>CAMARA Sandbox - SIM Swap - startelelogic lab <span style={{ fontSize: '18px', fontWeight: '500', }}>API is part of</span> <span id='Apidocs' style={{ textDecoration: 'underline', }}> CAMARA APIs by startelelogic.</span>
                </p>

                <p >
                  This sandboxed version is limited to a short list of mobile devices (MSISDNs) that only work in our lab.
                </p>

                <h3>What it does?</h3>

                <p>
                  The CAMARA SIM Swap API performs real-time checks on the activation date of a SIM card on the mobile network. It reveals if an individual mobile line has been ported to another SIM card.
                </p>

                <p>The SIM Swap API is useful to prevent fraud by reducing the risk of account takeover fraud by strengthening SIM based authentication processes such as SMS One-time passwords. Fraudsters are using SIM Swap techniques to intercept SMS messages and reset passwords or receive verification codes that allow them to access protected accounts.</p>

                <p>The SIM Swap API is used by an application to get information about a mobile line last SIM Swap date. It can be easily integrated and used through this secured API and allows SPs (Service Provider) to get this information an easy & secured way. The API provides management of 2 resources answering 2 distinct questions:</p>

                <ul>
                  <li>When did the last SIM Swap occur(*)?</li>
                  <li>Has a SIM Swap occurred during last n hours?</li>
                </ul>
              </div>

            </TabPanel>
            <TabPanel value="2">Getting Started</TabPanel>

            {/*Api Refference page importing here */}
            
            <TabPanel value="3"><ApiReference /></TabPanel>


            <TabPanel value="4">Terms</TabPanel>
            <TabPanel value="5">Contact us</TabPanel>
          </TabContext>

        </div>

      </Box>
      <Footer />
    </>
  );
};

export default SIMSwapDetection;