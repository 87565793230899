import React from 'react';
import './Footer.css';
import StarteleName from '../../../Assets/Startele.png';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-logo">
        <img
           src={StarteleName}
        />

      </div>

      <div className="footer-links">
        <div className="footer-column">
          <h4>PRODUCTS</h4>
          <ul>
            <li><Link to="/device-location">Device Location</Link></li>
            <li><Link to="/sim-swap-detection">SIM Swap Detection</Link></li>
            <li><Link to="/device-status-monitoring">Device Status Monitoring</Link></li>
            <li><Link to="/otp-validation">OTP Validation</Link></li>
           
          </ul>
        </div>

        <div className="footer-column">
          <h4>CORPORATE</h4>
          <ul>
            <li><Link to='/about-us'>About us</Link></li>
            {/* <li><a href="#">Newsroom</a></li> */}
            <li><a href="#">Events</a></li>
            

            {/* <li><a href="#">Careers</a></li> */}
          </ul>
        </div>

        <div className="footer-column">
          <h4>SOCIAL</h4>
          <ul>
            <li><a href="https://www.facebook.com/startelelogic/" target='_blank'>Facebook</a></li>
            <li><a href="https://www.linkedin.com/company/startelelogic/" target='_blank'  >LinkedIn</a></li>
            <li><a href="https://x.com/startelelogic?lang=en" target='_blank'>Twitter</a></li>
            <li><a href="https://www.youtube.com/channel/UCaVufyKNouVaczs2gyoYTWA" target='_blank'>YouTube</a></li>
            <li><a href="https://www.instagram.com/startelelogic/" target='_blank'>Instagram</a></li>
          </ul>
        </div>

        <div className="footer-column">
          <h4>MARKETPLACE</h4>
          <ul>
          <ul>
            <li><Link to='/developer-center' >Developer center</Link></li>
            {/* <li><a href="#">Become a partner</a></li> */}
            {/* <li><a href="#">System status</a></li> */}
            {/* <li><a href="#">Support</a></li> */}
        
          </ul>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
