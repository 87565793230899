import './App.css';

import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom';
import Home from './Components/HomePage/HomePage';
import Login from './Components/Sessions/Login/Login';
import SignUp from './Components/Sessions/SignUp/SignUp';
import DeviceLocation from './Components/DeviceLocation/DeviceLocation';
import SIMSwapDetection from './Components/SIMSwapDetection/SIMSwapDetection';
import DeviceStatusMonitoring from './Components/DeviceStatusMonitoring/DeviceStatusMonitoring';
import OTPValidation from './Components/OTPValidation/OTPValidation';
import ApiKey from './Components/ApiKey/ApiKey';
import Guides from './Components/Guides/Guides';
import { ToastContainer } from 'react-toastify';
import ForgotPassword from './Components/Sessions/Forgot Password/ForgotPassword'
import Nav from './Components/Navbar/Nav';
import UserProfile from './Components/UserProfile/UserProfile';
import AboutUs from './Components/HomePage/AboutUs/AboutUs';
import DeveloperCenterPage from './Components/HomePage/DeveloperCenter/DeveloperCenter';

function App() {
  return (
    <div className='App' >
      <Router>

      
        <Nav/>
        <ToastContainer/>
        <Routes>
       
          <Route path="/" element={<Home />} />
          <Route path='/login' element ={<Login/>} />
          <Route path='/signup' element={<SignUp/>} />
          <Route path='/forgotPassword' element={<ForgotPassword/>} />
          <Route path="/user-setting" element={<UserProfile />} />

          {/* <Route path="/guides" element={<Guides />} /> */}
          
         {/* products  route*/}
         <Route path="/device-location" element={<DeviceLocation />} />
         <Route path="/sim-swap-detection" element={<SIMSwapDetection />} />
         <Route path="/device-status-monitoring" element={<DeviceStatusMonitoring />} />
         <Route path="/otp-validation" element={<OTPValidation/>} />



         {/* <Route path="/apis-key" element={<ApiKey />} /> */}
         <Route path="/about-us" element={<AboutUs />} />
           <Route path="/developer-center" element={<DeveloperCenterPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
