import React, { useState } from 'react';
import { Box, Tab } from '@mui/material';
import './DeviceStatusMonitoringApiReference.css';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Card, CardMedia, CardContent, Typography, Button } from '@mui/material';
// import cardImg from '../../Assets/codeImg.png'
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useEffect } from 'react'
import { lookup } from 'country-data';  // import the country-data package

const DeviceStatusMonitoringApiReference = () => {
  // API Reference Tab panel states 
  const [ApiReferenceValue, setApiReferenceValue] = useState('1');

  const handleAPITab = (event, newValue) => {
    setApiReferenceValue(newValue);
  };

  const LoginStatus = localStorage.getItem('isLoggedIn')
  const [country, setCountry] = useState('');
  const UserUUId = localStorage.getItem('UserUUId')
  const AuthToken = localStorage.getItem('AuthToken'); // for getting the auth token from local storage



  // OpenApiSpec for showing the api name, url, response and parameter in the swagger
  const openApiSpec = {
    "openapi": "3.0.0",
    "info": {
      "title": "Device Status Monitoring",
      "version": "1.0.0"
    },
    "servers": [
      {
        "url": "https://camara-api.callibry.com:3001/api/v1"
      }
    ],
    "paths": {
      "/device-status-monitoring": {
        "post": {
          "tags": ["Device Status Monitoring"],
          "summary": "Device Status Monitoring",
          "requestBody": {
            "description": "Device status credentials",
            "required": true,
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {
                    "phone_number": {
                      "type": "string",
                      "default": "+33699901032"

                    },
                    "max_age": {
                      "type": "integer",
                      "default": 60
                    },

                  }
                }
              }
            }
          },
          "responses": {
            "200": {
              "description": "Ok"
            },
            "400": {
              "description": "Bad Request"
            },
            "401": {
              "description": "Unauthorized"
            },
            "403": {
              "description": "Forbidden"
            },
            "404": {
              "description": "Not Found"
            },
            "405": {
              "description": "Method Not Allowed"
            },
            "500": {
              "description": "Internal Server Error"
            },
            "502": {
              "description": "Bad Gateway"
            },

          }
        }
      }
    }
  };



  // Storing the api response and sending into the logs api

  const responseInterceptor = async (res) => {
    // const data = res.body;
    // console.log(data, 'dataaaaaaaaaaaaaaa');

    if (res.status === 401) {
      // Display a custom message to the user
      alert("Unauthorized access. Please access your token from the profile page.");
      window.location.href = '/user-setting';
    }

    const FirstName = localStorage.getItem('FirstName');
    const LastName = localStorage.getItem('LastName');

    const FullName = FirstName.trim() + " " + LastName.trim()

    // console.log(FullName, 'ffffffffffff')

    const date = new Date();

    // formatin the date 
    const UTCFormatDate = date.toISOString();



    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    // for getting the ip address and network location for logs api parameter

    const IPRes = await fetch("https://ipinfo.io/122.161.51.67?token=781b8c09d82125", requestOptions)

    const IPResponse = await IPRes.json();
    const countryCode = IPResponse.country;

    // for covnert isocode2 to full country name 
    const getCountryName = (iso2Code) => {
      const countryData = lookup.countries({ alpha2: iso2Code })[0];
      return countryData ? countryData.name : 'Unknown Country';
    }

    const countryName = getCountryName(countryCode) || 'Unknown Country';
    // setCountry(countryName);







    //  logs api url here 
    await fetch('https://camara-api.callibry.com:3001/logs', {
      method: 'POST', headers: {
        'Content-Type': 'application/json',
      }, body: JSON.stringify({
        "status": "1",
        "method": "POST",
        "endpoint": "/get-device-location",
        "uuid": UserUUId,
        "name": FullName,
        "ipAddress": IPResponse.ip,
        "city": IPResponse.city,
        "region": IPResponse.region,
        "country": countryName,
        "location": IPResponse.loc,
        "org": IPResponse.org,
        "postal": IPResponse.postal,
        "timezone": IPResponse.timezone,
        "mac": "",
        "date": UTCFormatDate,
      }
      )
    })
      .then(response => response.json())
      .then(data => {
        // console.log(data); 
      })

    return res;
  }

  return (
    <>
      <div className="main-container-device-status">
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={ApiReferenceValue}>
            <Box sx={{ borderColor: 'divider' }}>
              <TabList onChange={handleAPITab} aria-label="lab API tabs example">
                <Tab label="SwaggerUI" value="1" />
                {/* <Tab label="Rapidoc" value="2" /> */}
              </TabList>
            </Box>
            <TabPanel value="1">
              <div className="swagger-mainContainer">
                <div className="device-status-content">
                  <div className="device-status-title">
                    <h2>Device Status Monitoring</h2>
                    <p>The Device Status Monitoring API allows developers to monitor the status of devices in real-time, providing essential information for operational efficiency.</p>
                  </div>
                </div>

                <div className="introduction-content">
                  <div className="device-status-title">
                    <h2>Introduction</h2>
                    <p>This API provides real-time updates on the operational status of various devices within a network. It helps in detecting issues promptly and maintaining optimal performance.</p>
                    <p>The API enables users to track device connectivity, performance metrics, and alerts for any anomalies, facilitating better decision-making.</p>
                    <p>The API manages resources answering the following questions:</p>
                    <p>
                      <li>Is the device online or offline?</li>
                      <li>What is the current performance status of the device?</li>
                    </p>
                  </div>
                </div>

                <div className="relevant-terms-content">
                  <div className="relevant-terms-title">
                    <h2>Relevant terms and definitions</h2>
                    <p>
                      <li>
                        Device Status: The operational state of a device, which can be online, offline, or in error state, providing insights into its functionality.
                      </li>
                    </p>
                  </div>
                </div>

                <div className="api-functionality-content">
                  <div className="api-functionality-title">
                    <h2>API functionality</h2>
                    <p>The API provides the following operations:</p>
                    <p>
                      <li>GET status: Retrieves the current status of a specific device.</li>
                    </p>
                  </div>
                </div>

                <div className="further-info-content">
                  <div className="further-info-title">
                    <h2>Further Information</h2>
                    <p>Refer to the API documentation for detailed usage instructions and best practices for monitoring device statuses effectively.</p>
                    <p>(FAQs will be added in a later version of the documentation)</p>
                  </div>
                </div>
              </div>


              {
                LoginStatus === "true" ? (<Card className="hero-card">

                  {/* Showing the swagger ui for loggedIn user  */}

                  {/* <SwaggerUI url="https://camara-api.callibry.com:3001/login"/> */}
                  <SwaggerUI spec={openApiSpec} responseInterceptor={responseInterceptor} />


                </Card>) : (
                  <>
                    <div className="login-message">
                      <a href='/login'>Please Login to Access Full API Features</a>
                      <p>
                        You need to log in to fully interact with the APIs, but you can still explore the API documentation below.
                      </p>
                    </div>

                    <Card className="hero-card">
                      {/* Render Swagger UI for non-logged-in users as read-only */}
                      <SwaggerUI
                        spec={openApiSpec}

                        responseInterceptor={responseInterceptor}
                        docExpansion="none" // Collapse the docs for easier reading
                        supportedSubmitMethods={LoginStatus === "true" ? ['get', 'post', 'put', 'delete'] : []} // Disable try it out if not logged in
                      />
                    </Card>

                    <div className="swagger-info">
                      <h3 style={{ marginTop: '20px' }} >How to Use Our APIs</h3>
                      <p>
                        Our APIs provide a range of services that you can explore through the documentation above.
                        You can use this interface to test API endpoints once you log in. For now, you can review the
                        available endpoints and see how to structure your requests.
                      </p>
                      <ul>
                        <li><strong>Authentication:</strong> Login to get your API Access.</li>
                        <li><strong>Endpoints:</strong> View available services, such as device location, SIM swap detection, and OTP validation.</li>
                        <li><strong>Request format:</strong> Each endpoint shows example requests and response formats.</li>
                      </ul>
                      <p>
                        {/* For more information, please visit our <a href="/api-docs">API documentation page</a> or contact support. */}
                      </p>
                    </div>
                  </>
                )
              }

            </TabPanel>
            {/* <TabPanel value="2">Item Two</TabPanel> */}
          </TabContext>
        </Box>
      </div>
    </>
  );
};

export default DeviceStatusMonitoringApiReference;
