import React from 'react';
import './Products.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow, faSync, faChartLine, faKey } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, Link, useLocation } from 'react-router-dom';

const ProductCard = ({ icon, title, description , onClick}) => (
  <div className="product-card" onClick={onClick}>
    <div className="product-icon">{icon}</div>
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
);

const Products = () => {

  const navigate = useNavigate();

  const products = [
    { icon: <FontAwesomeIcon icon={faLocationArrow} />, title: 'Device Location', description: 'Device location enables personalized services and content delivery.', onClick:() => navigate('/device-location') },
    { icon: <FontAwesomeIcon icon={faSync} />, title: 'SIM Swap Detection', description: 'Detect unauthorized SIM swaps for enhanced account security.',onClick:() => navigate('/sim-swap-detection')  },
    { icon: <FontAwesomeIcon icon={faChartLine} />, title: 'Device Status Monitoring', description: 'Track and analyze device performance for optimal functionality.',onClick:() => navigate('/device-status-monitoring')  },
    { icon: <FontAwesomeIcon icon={faKey} />, title: 'OTP Validation', description: 'Verify user identity securely through one-time password validation.',onClick:() => navigate('/otp-validation')  },
  ];

  return (
    <div className="products-section">
      <h2>Our Products</h2>
      <p>Learn how to get started with tailored guides and examples.</p>
      <div className="product-main">
      <div className="product-list">
        {products.map((product, index) => (
          <ProductCard key={index} icon={product.icon} title={product.title} description={product.description}  onClick={product.onClick}  />
        ))}
      </div>
      </div>
     
    </div>
  );
};

export default Products;
