// import React from 'react'
// import Footer from '../Footer/Footer'

// const AboutUs = () => {
//   return (
//     <>
//         <div className="about-container">

//             <Footer/>
//         </div>
    
//     </>
//   )
// }

// export default AboutUs


import React from 'react';
import './AboutUs.css';
import integratity from '../../../Assets/integratity.avif'
import innoImg from '../../../Assets/innvotation.avif'
import committment from '../../../Assets/committment.avif'
import Footer from '../Footer/Footer';
import CustomizedTimeline from './CustomizedTimeline';
import { Box, Typography } from '@mui/material';

const AboutUs = () => {
  return (
    <div className="about-page">
      {/* Hero Section */}
      <div className="about-hero-container">
     <div className="about-hero-bg-image" />
     <Box className="about-hero-content">
        
        <Box className="about-hero-text">
          <Typography variant="h1" gutterBottom className="about-hero-title">
          We're glad you're here!
          </Typography>
          <Typography variant="body1" paragraph className="about-hero-description">
          At startelelogic, we drive innovation with state-of-the-art solutions and a commitment to excellence. Discover how we transform technology into opportunities for growth and success."
          </Typography>
    
        </Box>
        </Box>
        </div>

      {/* Mission Section */}
      <section id="mission" className="section">
        <div className="section-content" >
          <h2>Our Mission</h2>
          <p style={{textAlign:'start', marginTop:'5px'}}>To empower individuals and organizations through our services, ensuring long-term success and growth.</p>

          <p style={{textAlign:'start', marginTop:'5px'}}>The mission of the our API is to empower developers and organizations by providing seamless access to high-quality educational resources and tools. </p>
            <p style={{textAlign:'start', marginTop:'5px'}}>We aim to foster collaboration, innovation, and growth in the tech community, enabling users to create impactful solutions that enhance learning and accessibility worldwide</p>
        </div>
        
      </section>

      {/* Core Values Section */}
      <section id="values" className="section values-section">
        <div className="values-content">
          <h2>Our Core Values</h2>
          <div className="value-box">
            <div className="value-item">
              <img src={innoImg} alt="Innovation" />
              <h3>Innovation</h3>
              <p>We embrace new ideas and technologies to stay ahead of the curve.</p>
            </div>
            <div className="value-item">
              <img src={integratity} alt="Integrity" />
              <h3>Integrity</h3>
              <p>Our ethics and transparency guide all of our decisions and actions.</p>
            </div>
            <div className="value-item">
              <img src= { committment} alt="Commitment" />
              <h3>Commitment</h3>
              <p>We are committed to delivering the best possible outcomes for our clients.</p>
            </div>
          </div>
        </div>
      </section>
      <section id="values" className="section values-section">
      <div className="values-content">
      <h2>Our Timeline</h2>
      <CustomizedTimeline/>
      </div>
      </section>
      

      {/* Footer Section */}
      {/* <footer className="footer">
        <p>&copy; 2024 Your Company. All rights reserved.</p>
      </footer> */}
      <Footer/>
    </div>
  );
};

export default AboutUs;

