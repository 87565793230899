import React from 'react'
import './DeviceSwaggerApiReference.css'
import { Card, CardMedia, CardContent, Typography, Box, Button } from '@mui/material';
// import cardImg from '../../Assets/codeImg.png'
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react'
import { lookup } from 'country-data';  // import the country-data package





const DeviceSwaggerApiReference = () => {

  const LoginStatus = localStorage.getItem('isLoggedIn')
  const [country, setCountry] = useState('');
  const UserUUId = localStorage.getItem('UserUUId');
  const AuthToken = localStorage.getItem('AuthToken');





  // Swagger ui openApiSpec function for showing the api name, url and path name 

  const openApiSpec = {
    "openapi": "3.0.0",
    "info": {
      "title": "Device Location",
      "version": "1.0.0"
    },
    "components": {
      "securitySchemes": {
        "bearerAuth": {
          "type": "http",
          "scheme": "bearer",
          "bearerFormat": "JWT"
        }
      },
      "parameters": {
        "ContentType": {
          "name": "Content-Type",
          "in": "header",
          "required": true,
          "schema": {
            "type": "string",
            "default": "application/json"
          },
          "description": "Content type for the request"
        }
      }

    },
    "security": [
      {
        "bearerAuth": []
      }
    ],
    "servers": [
      {
        "url": "https://camara-api.callibry.com:3001/api/v1"
      }
    ],
    "paths": {
      "/get-device-location": {
        "post": {
          "tags": ["Device Location"],
          "summary": "Device Location",
          "requestBody": {
            "description": "Device Location credentials",
            "required": true,
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "properties": {  // This should be lowercase
                    "device": {
                      "type": "object", // The device should be defined as an object
                      "properties": {
                        "phoneNumber": {
                          "type": "string",
                          "default": "+33699901032"
                        }
                      }
                    },
                    "maxAge": {
                      "type": "integer", // Correct spelling
                      "default": 60
                    }
                  }
                }
              }
            }
          },
          "responses": {
            "200": {
              "description": "Ok"
            },
            "400": {
              "description": "Bad Request"
            },
            "401": {
              "description": "Unauthorized"
            },
            "403": {
              "description": "Forbidden"
            },
            "404": {
              "description": "Not Found"
            },
            "405": {
              "description": "Method Not Allowed"
            },
            "500": {
              "description": "Internal Server Error"
            },
            "502": {
              "description": "Bad Gateway"
            }
          }
        }
      }
    }
  };




  // const requestInterceptor = (req) => {
  //   if (req.headers['Content-Type'] === 'application/json') {
  //     const body = JSON.parse(req.body || '{}');
  //     body.user_uuid = UserUUId;
  //     req.body = JSON.stringify(body);
  //   }

  //   return req;
  // }



  // ResponseInterceptor for sending the api response in the logs api parameter

  const responseInterceptor = async (res) => {
    // const data = res.body;
    // console.log(data, 'dataaaaaaaaaaaaaaa');

    if (res.status === 401) {
      // Display a custom message to the user
      alert("Unauthorized access. Please access your token from the profile page.");
      window.location.href = '/user-setting';
    }

    const FirstName = localStorage.getItem('FirstName');
    const LastName = localStorage.getItem('LastName');

    const FullName = FirstName.trim() + " " + LastName.trim()

    // console.log(FullName, 'ffffffffffff')

    const date = new Date();

    // formatin the date 
    const UTCFormatDate = date.toISOString();



    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    // For getting the ip address and network location for logs api parameter

    const IPRes = await fetch("https://ipinfo.io/122.161.51.67?token=781b8c09d82125", requestOptions)

    const IPResponse = await IPRes.json();
    const countryCode = IPResponse.country;

    // convert the country iso2 to full country name 
    const getCountryName = (iso2Code) => {
      const countryData = lookup.countries({ alpha2: iso2Code })[0];
      return countryData ? countryData.name : 'Unknown Country';
    }

    const countryName = getCountryName(countryCode) || 'Unknown Country';
    // setCountry(countryName);






    //  logs api url 
    await fetch('https://camara-api.callibry.com:3001/api/v1/logs', {
      method: 'POST', headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${AuthToken}`
      }, body: JSON.stringify({
        "status": "1",
        "method": "POST",
        "endpoint": "/get-device-location",
        "uuid": UserUUId,
        "name": FullName,
        "ipAddress": IPResponse.ip,
        "city": IPResponse.city,
        "region": IPResponse.region,
        "country": countryName,
        "location": IPResponse.loc,
        "org": IPResponse.org,
        "postal": IPResponse.postal,
        "timezone": IPResponse.timezone,
        "mac": "",
        "date": UTCFormatDate,
      }
      )
    })
      .then(response => response.json())
      .then(data => {
        // console.log(data); 
      })

    return res;
  }

  // page open for the top when its to be open 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);





  return (
    <div className="swagger-mainContainer">
      <div className="deviceLocation-content">
        <div className="deviceLocation-title">
          <h2>Device Location</h2>
          <p>
            The Device Location API provides a programmable interface for developers and service providers to retrieve real-time location data for a mobile device. This can be useful for services such as fraud prevention, emergency services, or location-based marketing.
          </p>
        </div>
      </div>

      <div className="introduction-content">
        <div className="deviceLocation-title">
          <h2>Introduction</h2>
          <p>
            The Device Location API provides real-time or near real-time location data of mobile devices based on network data. The API allows service providers to securely access location data for specific mobile numbers.
          </p>
          <p>
            The Device Location API is useful for various services, including fraud detection, location-based marketing, and customer experience enhancement. By leveraging this API, providers can gain insight into the geographical movement of devices and use this data to enhance user interaction or prevent fraud.
          </p>
          <p>This API answers the following questions:</p>
          <ul>
            <li>What is the current or last known location of the device?</li>
            <li>Has the device moved from one location to another within a certain timeframe?</li>
          </ul>
        </div>
      </div>

      <div className="relevant-terms-content">
        <div className="relevant-terms-title">
          <h2>Relevant terms and definitions</h2>
          <ul>
            <li>
              <strong>Device Location:</strong> Device location refers to the geographical positioning of a mobile device as tracked by the network provider based on signals, such as GPS or cell tower triangulation.
            </li>
            <li>
              <strong>Geolocation:</strong> Geolocation is the process of determining the exact physical location of a device, typically through GPS data or network-based triangulation.
            </li>
          </ul>
        </div>
      </div>

      <div className="api-functionality-content">
        <div className="api-functionality-title">
          <h2>API functionality</h2>
          <p>The API provides 2 operations:</p>
          <ul>
            <li>POST retrieve-location: Provides real-time or last known location of a device for a given phone number.</li>
            <li>POST check-movement: Checks if the device has moved from one location to another during a past period (defined by the 'timeFrame' attribute) for a given phone number.</li>
          </ul>
        </div>
      </div>

      <div className="further-info-content">
        <div className="further-info-title">
          <h2>Further Info</h2>
          <p>
            The Device Location API follows industry standards for location data retrieval and security. It is compliant with various privacy regulations, ensuring the protection of user location data. For more about device location standards, please see the relevant documentation.
          </p>
          <p>(FAQs will be added in a later version of the documentation)</p>
        </div>
      </div>

      {
        LoginStatus === "true" ? (
          <Card className="hero-card">

            {/* Show the swagger ui for api calling when the user is loggedIn */}

            {/* <SwaggerUI url="https://camara-api.callibry.com:3001/login"/> */}
            <SwaggerUI spec={openApiSpec} responseInterceptor={responseInterceptor} />


          </Card>) : (
          <>
            <div className="login-message">
              <a href='/login' >Please Login to Access Full API Features</a>
              <p>
                You need to log in to fully interact with the APIs, but you can still explore the API documentation below.
              </p>
            </div>

            <Card className="hero-card">

              {/* Render Swagger UI for non-logged-in users as read-only */}

              <SwaggerUI
                spec={openApiSpec}

                responseInterceptor={responseInterceptor}
                docExpansion="none" // Collapse the docs for easier reading
                supportedSubmitMethods={LoginStatus === "true" ? ['get', 'post', 'put', 'delete'] : []} // Disable try it out if not logged in
              />
            </Card>

            <div className="swagger-info">
              <h3 style={{ marginTop: '20px' }} >How to Use Our APIs</h3>
              <p>
                Our APIs provide a range of services that you can explore through the documentation above.
                You can use this interface to test API endpoints once you log in. For now, you can review the
                available endpoints and see how to structure your requests.
              </p>
              <ul>
                <li><strong>Authentication:</strong> Login to get your API Access.</li>
                <li><strong>Endpoints:</strong> View available services, such as device location, SIM swap detection,device status monitoring and OTP validation.</li>
                <li><strong>Request format:</strong> Each endpoint shows example requests and response formats.</li>
              </ul>
              <p>
                {/* For more information, please visit our <a href="/api-docs">API documentation page</a> or contact support. */}
              </p>
            </div>
          </>
        )
      }



    </div>

  )
}

export default DeviceSwaggerApiReference
