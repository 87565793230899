import React from 'react';
import Footer from '../HomePage/Footer/Footer';
import img from '../../Assets/heroImg.jpeg';
import './ApisKeys.css'

const ApiKey = () => {


  return (
    <>
      <div className="technical-reference">
      <h2>Technical Reference</h2>

      <div className="compatibility-api">
        <h3>Compatibility API</h3>
        <div className="api-cards">
  

          <div className="card">
            <h4><a href="#">REST APIs</a></h4>
            <p>
              Use our compatible REST APIs to manage resources in your startelelogic Space.
            </p>
          </div>


         
        </div>
      </div>

      {/* Relay API Section */}
      <div className="relay-api">
        <h3>Relay API</h3>
        <div className="api-cards">
          {/* <div className="card">
            <h4><a href="#">JS Browser SDK</a></h4>
          </div> */}
          {/* <div className="card">
            <h4><a href="#">Node.js Server SDK</a></h4>
          </div> */}
          <div className="card">
            <h4><a href="#">Python</a></h4>
          </div>
          <div className="card">
            <h4><a href="#">JS (old)</a></h4>
          </div>
          <div className="card">
            <h4><a href="#">React</a></h4>
          </div>
          <div className="card">
            <h4><a href="#">Ruby</a></h4>
          </div>
          <div className="card">
            <h4><a href="#">REST APIs</a></h4>
          </div>
          <div className="card">
            <h4><a href="#">C#/.Net</a></h4>
          </div>
          <div className="card">
            <h4><a href="#">Electron</a></h4>
          </div>
        </div>
      </div>
    </div>
      {/* <Footer /> */}
    </>
  );
};

export default ApiKey;
