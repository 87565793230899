import React, { useRef } from 'react'
import './Navbar.css'
import StarteleName from '../../Assets/StarteleLogo.png';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Button, } from "@mui/material";
import { useState, useEffect } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { toast } from 'react-toastify';
import MenuIcon from '@mui/icons-material/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow, faSync, faChartLine, faKey } from '@fortawesome/free-solid-svg-icons';
import { AccessibilityOutlined, ApiOutlined, Login } from '@mui/icons-material';





const Nav = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation()

    const FirstName = localStorage.getItem('FirstName');
    const LastName = localStorage.getItem('LastName');
    const EmailId = localStorage.getItem('EmailId');
    const LoginStatus = localStorage.getItem('isLoggedIn')

    const [ShowSideNav, setShowSideNav] = useState(false);

    const customId = "forNotShowingMultipleToast"

    const [Toggle, setToggle] = useState(true)

    let activeUrl = (location.pathname === '/login' || location.pathname === '/signup' || location.pathname === '/signup')

    const ChangeToggle = () => {
        if (location.pathname == "/login") {
            setToggle(true)
        } else if (location.pathname == "/signup") {
            setToggle(false)
        }
    }


    useEffect(() => {
        ChangeToggle()
    })

    // State to manage the visibility of the user options menu
    const [menuVisible, setMenuVisible] = useState(false);

    // Function to toggle the menu visibility
    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };
    const profileInfoRef = useRef(null);


    // Close the menu if clicked outside of the ProfileInfo div
    const handleClickOutside = (event) => {
        if (profileInfoRef.current && !profileInfoRef.current.contains(event.target)) {
            setMenuVisible(false); // Close the menu
        }
    };

    // Set up event listener to detect clicks outside
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // logout api functions

    const handleLogout = () => {
        setShowSideNav(false)
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "email_id": EmailId
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://camara-api.callibry.com:3001/logout", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "1") {
                    toast.success(result.message, {
                        toastId: customId,
                        position: 'top-right',
                        autoClose: 2000,
                    })
                    localStorage.clear();

                    navigate('/login');
                }
                else {
                    toast.error(result.message, {
                        toastId: customId,
                        position: 'top-right',
                        autoClose: 2000,
                    })
                }
                // console.log(result)
            })
            .catch((error) => {
                console.error(error)
            });
    }

    return (
        <>

            <div className='navbar'>
                <Link to="/" >
                    <img
                        className='navbar-brand' src={StarteleName}
                    />

                </Link>
                {
                    !activeUrl ? (<>


                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <MenuIcon id='SideNavButton' onClick={() => setShowSideNav(!ShowSideNav)} />

                            <div className='sidenav' style={{ width: ShowSideNav ? '350px' : '0vw' }}>




                                {LoginStatus === "true" ? (<>
                                    <div className='sidenavbox-1'>
                                        <div className='Avatar' style={{ textTransform: 'uppercase', }} >
                                            <span>
                                                {FirstName.charAt(0)}
                                            </span>

                                            <span>
                                                {LastName.charAt(0)}
                                            </span>
                                        </div>

                                        <p id='name' >
                                            {FirstName} {LastName}
                                        </p>
                                        <a href="javascript:void(0)" className="closebtn" onClick={() => setShowSideNav(!ShowSideNav)} >&times;</a>
                                    </div>
                                </>) : (
                                    <>
                                        <div id='sidenavBox-Logout' className='sidenavbox-1'>
                                            <Link to="/" >
                                                <img
                                                    className='navbar-brand' src={StarteleName}
                                                />

                                            </Link>


                                            <a href="javascript:void(0)" className="closebtn" onClick={() => setShowSideNav(!ShowSideNav)} >&times;</a>
                                        </div>
                                    </>)}



                                <div className='sidenavbox-2'>
                                    <ul>
                                        <li><FontAwesomeIcon icon={faLocationArrow} id='icon' /><Link to="/device-location" onClick={() => setShowSideNav(!ShowSideNav)}> Device Location </Link></li>
                                        <hr id='borderLine' />
                                        <li><FontAwesomeIcon icon={faSync} id='icon' /><Link to="/sim-swap-detection" onClick={() => setShowSideNav(!ShowSideNav)}> SIM Swap </Link></li>
                                        <hr id='borderLine' />
                                        <li><FontAwesomeIcon icon={faChartLine} id='icon' /><Link to="/device-status-monitoring" onClick={() => setShowSideNav(!ShowSideNav)}> Device Status Monitoring </Link></li>
                                        <hr id='borderLine' />
                                        <li><FontAwesomeIcon icon={faKey} id='icon' /><Link to="/otp-validation" onClick={() => setShowSideNav(!ShowSideNav)}> OTP Validation </Link></li>
                                        <hr id='borderLine' />
                                        {/* <li><ApiOutlined id='icon' /><Link to="/apis-key" onClick={() => setShowSideNav(!ShowSideNav)}> API Keys </Link></li>
                                        <hr id='borderLine' /> */}
                                        <li><AccessibilityOutlined id='icon' /><Link to="/about-us" onClick={() => setShowSideNav(!ShowSideNav)}> About Us</Link></li>
                                        <hr id='borderLine' /> 
                                        {
                                            LoginStatus ? (<>
                                                <li><LogoutIcon id='icon' />
                                                    <div id='Logout-In-SideNavBar' onClick={() => handleLogout()}> Logout</div>
                                                   
                                                </li>
                                                <hr id='borderLine' />
                                            </>) :
                                                (<>
                                                    <li><Login id='icon' />
                                                    
                                                     <Link to='/login' onClick={() => setShowSideNav(!ShowSideNav)}> Log In</Link> 
                                                </li>
                                                </>)
                                        }


                                    </ul>

                                </div>
                            </div>
                            <div className='navbarMenu'>

                                <ul>
                                    {/* <li><Link to="/guides">Guides</Link></li> */}
                                    <li id='UserOption'

                                        onMouseEnter={() => setDropdownOpen(true)}
                                        onMouseLeave={() => setDropdownOpen(false)}
                                        className="dropdown-container"
                                    ><Link to="">Products </Link>
                                        {dropdownOpen && (
                                            <div className='UserOptionsMenu' >
                                                <ul>
                                                    <li onClick={() => setDropdownOpen(false)} ><Link to="/device-location"> Device Location</Link></li>

                                                    <li onClick={() => setDropdownOpen(false)}><Link to="/sim-swap-detection">SIM Swap </Link></li>

                                                    <li onClick={() => setDropdownOpen(false)}><Link to="/device-status-monitoring">Device Status Monitoring</Link></li>

                                                    <li onClick={() => setDropdownOpen(false)} ><Link to="/otp-validation">OTP Validation</Link></li>
                                                </ul>
                                            </div>
                                        )}
                                    </li>
                                    {/* <li><Link to="/apis-key">APIs Keys</Link></li> */}
                                    <li><Link to="/about-us">About Us</Link></li>
                                </ul>
                            </div>
                            {
                                LoginStatus === "true" ? (<>  <div className='ProfileInfo' onClick={toggleMenu} ref={profileInfoRef} >
                                    <>
                                        <div className='Avatar' style={{ textTransform: 'uppercase' }}>
                                            {/* <img src={Avatar} /> */}
                                            {/* {FullName && FullName.split(' ').map(name => name.charAt(0)).join('')} */}
                                            <span>{FirstName.charAt(0)}</span><span>{LastName.charAt(0)}</span>
                                        </div>

                                        <p id='name' >
                                            {FirstName} {LastName}


                                        </p>


                                    </>

                                    <div className={`UserOptionsMenu ${menuVisible ? 'visible' : ''}`}>
                                        <ul>
                                            {/* {Account_Type == 'Job_Seeker' && */}
                                            <li onClick={() => navigate('/user-setting')} >User Settings
                                                <ManageAccountsIcon style={{
                                                    fontSize: '18px',
                                                    color: '#122E54'
                                                }} />
                                            </li>
                                            {/* } */}

                                            <li onClick={handleLogout} >Logout <LogoutIcon style={{
                                                fontSize: '18px',
                                                color: '#122E54'
                                            }} /> </li>
                                        </ul>
                                    </div>
                                </div></>) : ''
                            }
                        </div>

                    </>
                    ) :


                        (<>



                            <div className='Toggle ' >
                                <Link to="/login" >
                                    <div className='loginToggle' style={{ backgroundColor: Toggle ? '#6D9EC0' : '', }}
                                        onClick={() => setToggle(true)}
                                    >
                                        <p> Login </p>
                                    </div>
                                </Link>

                                <Link to="/signup" >
                                    <div className='signupToggle' style={{
                                        backgroundColor: Toggle ? '' : '#6D9EC0',
                                    }}
                                        onClick={() => setToggle(false)}
                                    >
                                        <p> Sign Up</p>
                                    </div>
                                </Link>
                            </div>





                        </>)
                }

            </div>


        </>
    )
}

export default Nav
