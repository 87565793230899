import React, { useState } from 'react'
import './UserProfile.css'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { toast } from 'react-toastify';

import EngineeringIcon from '@mui/icons-material/Engineering';
import { Divider } from '@mui/material';
import { ContactPageOutlined, ContactPhone, Verified } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import TokenIcon from '@mui/icons-material/Token';



const UserProfile = () => {
  const FirstName = localStorage.getItem('FirstName');
  const LastName = localStorage.getItem('LastName');
  const AuthToken = localStorage.getItem('AuthToken'); // for getting the auth token from local storage
  const EmailId = localStorage.getItem('EmailId');
  const PhoneNumber = localStorage.getItem('PhoneNumber');
  const EmailStatus = localStorage.getItem('EmailStatus')

  const customId = "forNotShowingMultipleToast"

  const firstName = 'Prashant';
  const lastName = 'kumar'
  const [value, setValue] = useState('1');

  const initials = `${FirstName[0]}${LastName[0]}`.toUpperCase();

  const [isVisible, setIsVisible] = useState(false);


  const token = AuthToken;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(token);
    toast.success('Token copied successfully', {
      toastid: customId,
      position: 'top-right',
      autoClose: 2000,
    })
  };


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>

      <div className="profile-container">
        <div className="user-profile">
          <div className="user-profile-heading">
            <h2>User Profile Setting <SettingsSuggestIcon fontSize='large' /></h2>
          </div>

          <div className="tab-container">
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChange} aria-label="user profile setting option">
                    <Tab label="Profile Details" value="1" />
                    {/* <Tab label="Password Change" value="2" /> */}

                  </TabList>
                </Box>
                <TabPanel value="1">


                  <div className="personal-detail">
                    <div className="personal-header-container">
                      <h2 className='person-detail-heading'>Personal Detail  <EngineeringIcon fontSize='large' /> </h2>
                    </div>
                    <Divider />


                    <div className="personal-detail-container">
                      <div className="dp-container">
                        <div className="circle-dp">
                          <span>{initials}</span>
                        </div>
                      </div>

                      <div className="detail">
                        <div className="UserInfo-header-container">
                          <h2 className='UserInfo-detail-heading'>User Info  <PersonIcon fontSize='large' />  </h2>
                        </div>
                        <Divider />
                        <div className="names">
                          <div className="firstName">First Name : <span>{FirstName} </span></div>
                          <div className="lastName">Last Name : <span>{LastName} </span></div>
                          {/* <div className="fullName">Full Name : <span> Prashant kumar </span></div> */}
                        </div>




                        <div className="contact">

                          <div className="contact-header-container">
                            <h2 className='contact-detail-heading'>Contact  <ContactPageOutlined fontSize='large' />  </h2>
                          </div>
                          <Divider />

                          < div className="mobile-email">
                            <div className="email">Email : <span>{EmailId}</span></div>
                            <div className="mobile">Mobile : <span>{PhoneNumber} </span></div>
                            <div className="status">Email Status: <span> {EmailStatus === "true" ? "verified" : "Not-verified" } </span></div>
                          </div>

                        </div>
                        <div className="token">

                          <div className="token-header-container">
                            <h2 className='token-detail-heading'>Token  <TokenIcon fontSize='large' />  </h2>
                          </div>
                          <Divider />

                          <div className="token-container">
                            <div className="token-display">
                              {isVisible ? token : '••••••••••••••'}
                            </div>
                            <button className="toggle-button" onClick={() => setIsVisible(!isVisible)} style={{width: '60px'}}>
                              {isVisible ? 'Hide' : 'Show'}
                            </button>
                            <button className="copy-button" onClick={copyToClipboard}>
                              Copy Token
                            </button>
                          </div>
                        </div>
                      </div>






                    </div>







                    <Divider />

                  </div>








                </TabPanel>
                <TabPanel value="2">Item Two</TabPanel>

              </TabContext>
            </Box>
          </div>
        </div>


      </div>


    </>
  )
}

export default UserProfile
