import React, { useEffect, useState } from 'react';
import { Card, CardMedia, CardContent, Typography, Box, Button, CardActionArea, CardActions } from '@mui/material';
import './HeroSection.css'; // Importing the custom CSS for styling
// import cardImg from '../../Assets/codeImg.png'
// import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { useNavigate, Link, useLocation } from 'react-router-dom';
import imgb from "../../Assets/Otp.jpg"











const HeroSection = () => {



  const navigate = useNavigate();
  const LoginStatus = localStorage.getItem('isLoggedIn')
  // const date = new Date.toISOString();
  // console.log(date, 'ddddddddddddddddddddddd')


  // For open the page to the top 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (

    <div className="hero-container">
      <div className="hero-bg-image" />

      {/* Left Section: Text */}
      {/* <Box className="hero-text">
        <Typography variant="h2" gutterBottom>
          Welcome to Our Platform
        </Typography>
        <Typography variant="body1" paragraph>
          We provide the best solutions for your business growth. Join us and explore the endless possibilities of technology.
        </Typography>
        <Button variant="outlined" color="white" size="large" onClick={() => navigate('/apis-key')}>
          Get Started
        </Button>
      </Box> */}



      <Box className="hero-content">
        {/* Left Section: 3D Heading and Text */}
        <Box className="hero-text">
          <Typography variant="h1" gutterBottom className="hero-title">
            Welcome to Our Platform
          </Typography>
          <Typography variant="body1" paragraph className="hero-description">
            We provide the best solutions for your business growth. Join us and explore the endless possibilities of technology.
          </Typography>
          <Button variant="outlined" color="primary" size="large" onClick={() => navigate('/about-us')} className="hero-button">
            Get Started
          </Button>
        </Box>

        {/* Right Section: Image Card with Cracking Glass Animation */}
        {/* <div className="hero-card">
          <img
            src={imgb}
            alt="Networking"
            className="networking-image"
          />
        </div> */}










        {/* Right Section: Image Card */}
        {/* <Card className="hero-card">

          <SwaggerUI url="https://camara-api.callibry.com:3001/login" />
          <SwaggerUI spec={openApiSpec} requestInterceptor={requestInterceptor} responseInterceptor={responseInterceptor} />


        </Card> */}

        {!LoginStatus ? (<>
          <div className='hero-card' >

            <div style={{ display: 'flex', justifyContent: 'center', gap: 10, }}>

              <Button
                size="large"
                className="Button_2"
                color='error'
                variant="outlined"
                
                onClick={() => navigate('/login')}

              >
                Log In
              </Button>
              <Button

                size="large"
                className="Button_2"
                variant="outlined"
                sx={{ px: 4, py: 2 }}
                onClick={() => navigate('/signup')}
              >
                Sign Up
              </Button>

            </div>



          </div>

        </>) : ''}

      </Box>

    </div>
  );
};

export default HeroSection;